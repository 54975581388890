








































import { ElForm } from 'element-ui/types/form';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  addWeatherType,
  checkWeatherTypeName,
  deleteWeatherType,
  getWeatherTypeList,
} from '@/api/weather-type';

@Component({
  name: 'WeatherType',
})
export default class extends Vue {
  @Watch('sort')
  private handleChangeSort(sort: string) {
    /* eslint-disable */
    switch (sort) {
      case 'date':
        this.weatherTypes.sort((a: any, b: any) => {
          return new Date(b.createDate).valueOf() - new Date(a.createDate).valueOf();
        });
        break;
      case 'word':
        this.weatherTypes.sort((a: any, b: any) => {
          const x = a.name.toLowerCase();
          const y = b.name.toLowerCase();
          if (x < y) return -1;
          if (y > x) return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    /* eslint-enable */
  }

  private form = {
    name: '',
  };

  private formLoading = false;

  private listLoading = true;

  private rules = {
    name: [
      { required: true, message: '기상영향명를 입력해주세요.', trigger: 'blur' },
      { validator: this.wordValidator, trigger: 'blur' },
    ],
  }

  private sort = 'word';

  private wordValidator(rules: any, value: string, callback: Function) {
    checkWeatherTypeName(value).then((res) => {
      if (res.data) callback();
      else callback(new Error('중복된 기상영향명입니다.'));
    }).catch(() => {
      callback(new Error('사용할 수 없는 기상영향명입니다.'));
    });
  }

  private weatherTypes = []

  private handleAdd(form: any) {
    (this.$refs.weatherTypeForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.formLoading = true;
        addWeatherType(form).then(() => {
          this.form.name = '';
          this.getWeatherTypeList();
          this.formLoading = false;
        }).catch((error) => {
          this.$message.error(error.response.data);
        });
      }
    });
  }

  private getWeatherTypeList() {
    this.listLoading = true;
    getWeatherTypeList().then((res) => {
      this.weatherTypes = res.data;
      this.handleChangeSort(this.sort);
      this.listLoading = false;
    });
  }

  private handleDelete(item: any) {
    this.$confirm(`정말 ${item.name}을(를) 삭제하시겠습니까?`, 'Warning', {
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deleteWeatherType(item.name).then(() => {
        this.getWeatherTypeList();
      });
    });
  }

  mounted() {
    this.getWeatherTypeList();
  }
}
